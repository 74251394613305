import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Card, CardMedia, CardContent, List, ListItem, ListItemButton, Button, 
  CardActions, useTheme, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
  Box} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import AddDesignSet from './AddDesignSet';
import AddDocumentToSet from './AddDocumentToSet';
import RemoveDocument from './RemoveDocument';
import { Authenticated } from "../AuthenticatedComponent";

const designPermissions = ["Design_Document_Writer", ];

const LeftGridContent = ({ selectedTab, selectedItem, onItemClick, onDocumentAdded }) => {
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [subTab, setSelectedSubTab] = useState(0);
  //eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [selectedTab, subTab, onDocumentAdded]);

  const fetchData = () => {
    setLoading(true);
    const apiEndpoint =
      selectedTab === 0 && subTab === 0
        ? '/api/ProductDocumentation/documentSets/CF (FM)'
        : selectedTab === 0 && subTab === 1
        ? '/api/ProductDocumentation/documentSets/CF (NON-FM)'
        : selectedTab === 0 && subTab === 2
        ? '/api/ProductDocumentation/documentSets/BSU (NON-FM)'
        : selectedTab === 1
        ? '/api/ProductDocumentation/formsAndManuals'
        : '/api/ProductDocumentation/archivedDocumentSets';

     axios.get(apiEndpoint)
      .then((response) => {
        setItems(response.data || []);
      })
      .catch((error) => {
        alert('Error fetching items for the list.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleTabChange = (event, newValue) => {
    setSelectedSubTab(newValue);
  };

  const titles = ['Design Docs', 'Manuals and Specification Sheets', 'Archive'];

  return (
    <Paper elevation={3} sx={{ maxHeight: '80vh', overflowY: 'auto', width: '96%' }}>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1, backgroundColor: theme.palette.background.paper, padding: '8px 0'}}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ paddingLeft: '10px' }} variant="h6">{titles[selectedTab]}</Typography>
            { selectedTab === 0
            ?
              <IconButton onClick={() => setInfoModalVisible(true)} sx={{ padding: "0px 4px" }}>
                <InfoIcon />
              </IconButton>
            : ''}
          </Box>
          <div style={{ paddingRight: '10px' }}>
          { selectedTab !== 2 && (
            <AddDesignSet 
              onDesignSetAdded={fetchData} 
              productDocumentType={selectedTab === 0 ? "design" : "form"} 
              productType={selectedTab === 1 ? "" : subTab === 0 ? "CF (FM)" : subTab === 1 ? "CF (non-FM)" : subTab === 2 ? "BSU (non-FM)" : ""} 
              formType={"Design set"} 
            />
          )}
          </div>
        </Box>

        {selectedTab === 0 && (
          <Tabs
            value={subTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            sx={{ marginBottom: 2 }}
          >
            <Tab label="CF (FM)" />
            <Tab label="CF (non-FM)" />
            <Tab label="BSU (non-FM)" />
          </Tabs>
        )}
      </Box>
      <List>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListItemButton
              onClick={() => onItemClick(item, selectedTab === 0 ? 'design' : 'manual')}
              sx={{
                backgroundColor: selectedItem?.documentSubtypeId === item.documentSubtypeId
                  ? 'rgba(255, 0, 0, 0.2)'
                  : theme.palette.mode === 'light'
                    ? '#E8E8E8'
                    : '#1D1D1D',
                transition: 'background-color 0.3s ease',
                borderRadius: '8px',
              }}
            >
              <Typography variant="body1"> {item.documentName} {item.documentTypeId === 1 ? `(Set ${item.versionNo})` : ""} </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Dialog open={infoModalVisible} onClose={() => setInfoModalVisible(false)}>
        <DialogTitle>Design set Addition Info</DialogTitle>
        <DialogContent>
          <Typography>
            <strong>Adding new Design set</strong>: When adding a new design set you will need to add all files that are going to be a part of that set in bulk. Any files added to the set
            after initial save will be treated as a revision which will generate a new design set with updated revision number containing all previous files
            in addition to the new one.<br /><br />

            <strong>Adding new File</strong>: Files added to existing design set will need to be added one at a time. Again, this will create a new set with all previous files
            and new file on save. If the file is a revised version of an existing one in the set, the system will create a new design set, replace the existing file with the new
            revised one, and bring along the previous files on save.<br /><br />

            <strong>Adding new Forms</strong>: The aforementioned logic will not effect forms. Forms do not have revision numbers and can be added/removed at will.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setInfoModalVisible(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const RightGridContent = ({ selectedTab, selectedItem, onDocumentClick, onDocumentAdded }) => {
  const theme = useTheme();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [selectedItem]);

  const fetchData = () => {
    if (selectedItem?.documentSubtypeId) {
      axios
        .get(`/api/ProductDocumentation/productDocuments?docSubtypeId=${selectedItem.documentSubtypeId}`)
        .then((response) => {
          setDocuments(response.data || []);
        })
        .catch((error) => {
          alert('Error fetching documents for the selected item.');
        });
    }
  }

  return (
    <div style={{ maxHeight: '89vh', overflowY: 'auto', padding: 2 }}>
      <div
        style={{
          textAlign: "right",
          position: "sticky",
          top: -2,
          backgroundColor: theme.palette.background.paper,
          zIndex: 1,
          paddingBottom: "8px",
          paddingTop: "8px",
          paddingRight: "8px",
        }}
      >
        { selectedTab !== 2
          ? <AddDocumentToSet 
            onDesignSetAdded={() => {fetchData(); onDocumentAdded();}} 
            productDocumentType={selectedTab === 0 ? "design" : "form"} 
            subtypeId={selectedItem?.documentSubtypeId} />
          : null }
      </div>
      <Grid container spacing={2}>
        {documents.map((doc, index) => (
          <Grid item xs={6} key={index}>
            <Card>
              <CardMedia
                component={
                  [".jpg", ".jpeg", ".png", ".gif", ".bmp"].includes(
                    doc.documentPath.extensionType
                  )
                    ? "img"
                    : "iframe"
                }
                alt="File"
                src={doc.documentPath}
                title="Current Design"
                height="270"
              />
              <CardContent style={{height: "80px"}} onClick={() => onDocumentClick(doc)} sx={{ '&:hover': { cursor: 'pointer' } }}>
                <Typography variant="body1" sx={{ '&:hover': { textDecoration: 'underline' } }}>
                  {doc.documentName || 'No file name provided'}
                </Typography>
              </CardContent>
              <CardActions>
                <RemoveDocument onDocumentRemoved={() => {fetchData(); onDocumentAdded();}} documentId={doc.documentId} selectedTab={selectedTab} />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default function ProductDocumentation() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedTab, setSelectedTab] = useState(Authenticated(designPermissions) ? 0 : 1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentAdded, setDocumentAdded] = useState(false);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedItem(null);
  };

  const handleItemClick = (item, type) => {
    setSelectedItem({ ...item, type });
  };

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDocument(null);
  };

  const handleDocumentAdded = () => {
    setDocumentAdded(!documentAdded);
  }

  return (
    <>
      <Grid container spacing={1} sx={{ height: '90vh', padding: 2 }}>
        <Grid item xs={6}>
          { Authenticated(designPermissions) ?
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              sx={{ marginBottom: 2 }}
            >
                  <Tab key="design-docs" label="Design Docs" />,
                  <Tab key="manuals-spec-sheets" label="Manuals & Spec Sheets" />,
                  <Tab key="archive" label="Archive" />
            </Tabs>
           : '' }
          <LeftGridContent
            selectedTab={selectedTab}
            selectedItem={selectedItem}
            onItemClick={handleItemClick}
            onDocumentAdded={documentAdded}
          />
        </Grid>
        <Grid item xs={6}>
          <RightGridContent
            selectedTab={selectedTab}
            selectedItem={selectedItem}
            onDocumentClick={handleDocumentClick}
            onDocumentAdded={handleDocumentAdded}
          />
        </Grid>
      </Grid>

      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="lg" fullWidth>
        <DialogContent>
          {selectedDocument?.documentPath.endsWith('.pdf') ? (
            <iframe
              src={selectedDocument.documentPath}
              title={selectedDocument.title}
              style={{ width: '100%', height: '80vh', border: 'none' }}
            />
          ) : (
            <CardMedia
              component="img"
              alt={selectedDocument?.title}
              src={selectedDocument?.documentPath}
              title={selectedDocument?.title}
              style={{ objectFit: 'contain', width: '100%', height: '80vh' }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}