import React, { useState, useEffect } from "react";
import { Grid, Box, Stack, Typography, Button, FormControl, InputLabel, Select, MenuItem, Modal, TextField, Dialog, DialogTitle, DialogContent, DialogActions }from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import dayjs from "dayjs";
import { GetAuthUserName } from "../AuthenticatedComponent";

const ptoTypes = [
  { key: 1, type: "Vacation" },
  { key: 2, type: "Sick" },
  { key: 3, type: "Personal Day" },
  { key: 4, type: "Other" }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40%',
  overflow: 'scroll',
  overflowX: "hidden",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PTORequestForm( props ) {

  const [user, setUser] = useState(null);
  const [teamNumber, setTeamNumber] = useState(null);
  const [ptoType, setPtoType] = useState("");
  const [note, setNote] = useState("");
  const [dayOut, setDayOut] = useState(null);
  const [returnDate, setReturnDate] = useState(null);
  const [holidayList, setHolidayList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const dialogTitle = "Please Enter All Required Fields";
  const dialogText = "Required Fields: Planned Date Out, Planned Return, PTO Type";
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/getHoliday';
    axios.get(endpoint)
        .then((response) => {
            loadHolidays(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    // eslint-disable-next-line
  }, []);

  const loadHolidays = (holidays) => {
    let newRows = [];
    if (holidays.length > 0) {
        holidays.forEach(h => {
            newRows.push({date : dayjs(h.holidayDate).toDate()})
        })
    }
    setHolidayList(newRows);
  }


  const handlePTOTypeSelect = (event) => {
    setPtoType(event.target.value);
  }

  const handleCleanUp = () => {
    setPtoType("");
    setNote("");
    setDayOut(null);
    setReturnDate(null);
    setTeamNumber(null);
  };

  useEffect(() => {
    setLoading(true);
    const endpoint = 'api/Employee/employee';
    axios.get(endpoint)
        .then((response) => {
            loadEmployee(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    // eslint-disable-next-line
  }, []);

  const loadEmployee = (employees) => {

    if (employees.length > 0) {
      employees.forEach(e => {
          if (GetAuthUserName() === e.userName) {
            setUser(e);
          }
      })
    }
  }

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    var curDate = new Date(startDate.getTime());
    while (curDate < endDate) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  const getHolidayCount = (startDate, endDate) => {
    let count = 0;
    holidayList.map((holiday) => {
      if (startDate <= holiday.date && holiday.date < endDate) count++;
    })
    return count;
  }

  const handleSubmitRequest = () => {
    setLoading(true);

    const nullToZero = (val) => {
      if (val === null) {
        return 0
      } else {
        return val
      }
    }

    if (dayOut !== null && returnDate !== null && ptoType) {
      const requestedTime = (getBusinessDatesCount(new Date(dayOut), new Date(returnDate)) - getHolidayCount(new Date(dayOut), new Date(returnDate))) * 8

      const NewPTORequest = {
        employeeId: user.userId,
        timesheetId: null,
        firstName: user.firstName,
        lastName: user.lastName,
        department: user.department,
        teamNumber: !isNaN(+teamNumber) ? parseInt(teamNumber, 10) : null,
        requestDate: new Date().toISOString().substring(0, 10),
        ptoRequestStatus: "Pending",
        plannedDayOut: dayOut ? dayOut.toISOString().substring(0, 10) : null,
        plannedReturnDate: returnDate ? returnDate.toISOString().substring(0, 10) : null,
        ptoType: ptoType,
        ptoNote: note
      }

      axios
        .post("/api/TimeSheet/createPtoRequest", NewPTORequest)
        .then(() => {
        })
        .catch((error) => {
            console.error(error);
            alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
        })
        .finally(() => {
            props.updatePtoRequests();
            setLoading(false);
        });


      const updatedPto = {
        userId: user.userId,
        ptoTotal: user.ptoTotal,
        ptoPending: nullToZero(user.ptoPending) + requestedTime,
        ptoUsed: user.ptoUsed,
        ptoRemaining: user.ptoRemaining,
        rollOverPto: user.rollOverPto
      };

      axios
          .post('api/Employee/loadEmployeePto', updatedPto)
          .then((response) => {
          })
          .catch((error) => {
              console.log(error);
              if (error.response && error.response.data) {
                  alert(error.response.data);
              } else {
                  alert("Error updating Time Log. Please try again.");
              }
          })
          .finally(() => {
              setLoading(false);
              props.handlePTOModalClose();
              props.updatePtoDisplay();
              handleCleanUp();
              
          });
    } else {
      setOpenDialog(true);
    }
    
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {dialogText}
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
          </DialogActions>
      </Dialog>

      <Modal
          open={props.openPTOModal}
          onClose={props.handlePTOModalClose}
      >
          <Box sx={style}>
              <Button
                  variant="outlined"
                  onClick={props.handlePTOModalClose}
                  style={{ position: "absolute", top: "10px", right: "10px" }}
              >
                  X
              </Button>

              <Typography
                  variant="h6"
                  component="h2"
                  style={{ paddingBottom: "20px" }}
              >
                  PTO Request Form
              </Typography>

              <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                  <Stack>
                      <Stack paddingBottom="10%">
                        <TextField
                          label="Name"
                          value={user ? user.firstName + " " + user.lastName : null}
                          readOnly={true}
                        />
                      </Stack>
                      <Stack paddingBottom="10%">
                        <TextField
                          label="Department"
                          value={user ? user.department : null}
                          readOnly={true}
                        />
                      </Stack>
                      <Stack paddingBottom="10%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Planned Date Out"
                            value={dayOut}
                            onChange={setDayOut}
                          />
                        </LocalizationProvider>
                      </Stack>
                      <Stack width="100%" paddingBottom="10%">
                          <FormControl sx={{ width: "100%" }}>
                              <InputLabel id="pto-label">PTO Type</InputLabel>
                              <Select
                                  label="PTO Type"
                                  labelId="pto-label"
                                  id="pto-select"
                                  value={ptoType}
                                  onChange={handlePTOTypeSelect}
                              >
                                  {ptoTypes.map((pto) => (
                                      <MenuItem key={pto.key} value={pto.type}>
                                          {`${pto.type}`}
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </Stack>
                  </Stack>
                  <Stack width={"15%"}/>

                  <Stack>
                      <Stack paddingBottom="10%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Today's Date"
                            value={dayjs(new Date())}
                            readOnly={true}
                          />
                        </LocalizationProvider>
                      </Stack>
                      <Stack paddingBottom="10%">
                        <TextField
                          label="Team #"
                          value={teamNumber}
                          onChange={event => setTeamNumber(event.target.value)}
                        />
                      </Stack>
                      <Stack paddingBottom="10%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Planned Return"
                            value={returnDate}
                            onChange={setReturnDate}
                          />
                        </LocalizationProvider>
                      </Stack>
                      <Stack paddingBottom="10%">
                        <TextField
                          label="Additional Notes"
                          value={note}
                          onChange={event => setNote(event.target.value)}
                        />
                      </Stack>
                  </Stack>
              </Stack>

              <Grid
                  container
                  justifyContent="flex-end"
              >
                  <Button
                      style={{ color: "green" }}
                      onClick={handleSubmitRequest}
                      disabled={loading}
                  >
                      Submit
                  </Button>
              </Grid>
          </Box>
      </Modal>
    </>
  );
}