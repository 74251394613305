import React, { useState } from "react";
import { Button, Snackbar, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function RemoveDocument({ onDocumentRemoved, documentId, selectedTab }) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const dialogTitle = selectedTab === 0 ? "Remove document from design set?" : "Remove file from set?";
  const dialogContent = 
    selectedTab === 0 ? "Removing a document from this design set will create a new design set with updated revision number containing all previous documents (excluding the removed document)." 
    : "";

  const handleConfirmOpen = () => {
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const removeDocument = async () => {
    setLoading(true);

    try {
      await axios.delete(`/api/ProductDocumentation/deleteDocument/${documentId}`)
      .then(() => {
        onDocumentRemoved();
      })
    } catch (error) {
      alert(error.response?.data || `Error removing file. Please try again.`);
    } finally {
      setLoading(false);
      handleConfirmClose();
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={() => {handleConfirmOpen()}}>Remove</Button>

          <Dialog
            open={confirm}
            keepMounted
            onClose={handleConfirmClose}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogContent}
            </DialogContent>
            <DialogActions style={{padding: '20px'}}>
              <Button onClick={() => {handleConfirmClose()}}>Cancel</Button>
              <Button style={{color: 'green'}} onClick={() => {removeDocument() }}>Confirm</Button>
              {loading && <CircularProgress />}
            </DialogActions>
          </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          File removed!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
