import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, Snackbar, TextField, CircularProgress, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import { useMsal } from "@azure/msal-react";

export default function AddServiceLogNote({ productConfigId, onNoteAdded }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [user, setUser] = useState("");
  const [note, setNote] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  useEffect(() => {
    if (activeAccount) {
        setUser(activeAccount.name.split(' ')[0]);
    } else {
        setUser(null);
    }
  }, [activeAccount]);

  const notePayload = {
    logId: 0,
    productConfigId: productConfigId,
    username: user,
    note: note,
    logDate: new Date(new Date().getTime()),
    isComplete: false
  }

  const saveNote = () => {
    setLoading(true);
    axios
      .post("api/DeviceSheetNote/saveDeviceSheetNote", notePayload )
      .then(() => {
        setSnackbarOpen(true);
        setNote("");
        onNoteAdded();
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        alert("Error saving note. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{ fontFamily: "'Roboto Mono', monospace", marginLeft: 'auto' }} onClick={handleOpen}>
        Add Note
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: '375px',
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ fontFamily: "'Roboto Mono', monospace", textAlign: "center", paddingBottom: "20px" }}
          >
            Add Note
          </Typography>

          <Grid container spacing={2} style={{ padding: "0 30px" }}>
            <Grid item xs={12}>
              <TextField
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                fullWidth
                multiline
                rows={4}
                margin="normal"
                sx={{
                  "& .MuiInputBase-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                }}
              />
            </Grid>
          </Grid>

          <div style={{ position: "absolute", right: "10px", bottom: "10px" }}>
            {loading && <CircularProgress />}
            <Button
              style={{ color: "green", fontFamily: "'Roboto Mono', monospace" }}
              onClick={saveNote}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Note added successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
}

