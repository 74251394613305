import React, { useState, useEffect, useMemo } from "react";
import { Typography, useTheme } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSearchParams } from "react-router-dom";
import AddDocument from "./AddDocument";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function DocumentList(props) {
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [prodNames, setProdNames] = useState([]);
  const [prodTypes, setProdTypes] = useState([]);
  const theme = useTheme();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const documentAdded = () => {
    fetchData();
    props.prelimAdded();
  }

  const fetchData = () => {
    axios
      .get(`/api/Onboarding/documentList/${searchParams.get("id")}`)
      .then((response) => {
        const documentsData = Array.isArray(response.data)
          ? response.data
          : [];
        setData(documentsData);
      })
      .catch((error) => {
        alert("Error.");
      });
  };

  const fetchProducts = () => {
    axios
      .get(`api/Projects/products/${searchParams.get("id")}`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.log(`Error.`);
      });
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (Array.isArray(products)) {
      const productTypes = products.map(product => product.productType);
      const productNames = products.map(product => product.productName);
      setProdTypes(productTypes);
      setProdNames(productNames);
    } else {
      console.error("Error.");
    }
  }, [products]);

  if (props.obSubmit) {
    fetchData();
    props.onObTaskSubmit(false);
  }

  const downloadDocument = (documentLink) => {
    window.open(documentLink);
  };

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
  }

  const columns = useMemo(() => [
    {
      accessorKey: 'userName',
      header: 'User',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'dateTime',
      header: 'Date/Time',
      Cell: ({ cell }) => {
        const date = offsetDate(new Date(cell.getValue()));
        return date.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
      },
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'finalDesignDoc',
      header: 'Final Design',
      Cell: ({ cell }) => (
        cell.getValue() ? <CheckCircleIcon style={{ color: "green" }} /> : null
      ),
      size: 50,
      align: 'center',
      enableResizing: true,
    },
    {
      accessorKey: 'fileType',
      header: 'File Type',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'productFileName',
      header: 'File Name',
      size: 100,
      align: 'center'
    },
    {
      accessorKey: 'dscrption',
      header: 'Description',
      size: 100,
      align: 'center',
    },
    {
      accessorKey: 'versionNo',
      header: 'Version No.',
      size: 50,
      align: 'center',
    },
  ], []);

  const table = useMaterialReactTable({
    columns,
    data,
    defaultColumn: {
      maxSize: 100,
      minSize: 20,
      size: 20,
    },
    enableColumnResizing: false,
    enableRowHover: true,
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => downloadDocument(row.original.documentLink),
      style: {
        cursor: 'pointer',
      },
    }),
    enableStickyHeader: true,
    enableRowSelection: false,
    initialState: {
      pagination: { pageIndex: 0, pageSize: 10 },
    },
    enablePagination: true,
    muiTableContainerProps: {
      style: {
        maxHeight: '400px',
        minHeight: '400px',
      },
    },
    muiTableBodyProps: {
      align: 'center',
    },
  })
  

  return (
    <div style={{ height: '100%', width: '100%', paddingBottom: '10%' }}>
      <Typography variant="h5" gutterBottom style={{ display: "flex", alignItems: "center", marginTop: '5%', marginBottom: '3%' }}>
        <div style={{ paddingLeft: "40%" }}>
          Document List
        </div>
        <div style={{ flex: 1 }}></div>
        <AddDocument onDocumentAdded={documentAdded} products={products} productNames={prodNames} productTypes={prodTypes} />
      </Typography>

      <MaterialReactTable table={table} />
    </div>
  );
}


