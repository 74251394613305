import React, { useState, useEffect } from "react";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, TextField, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";

export default function EditSiteInfo({pcId, siteInfo, onSave}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // eslint-disable-next-line
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const [itContact, setItContact] = useState("");
  const [itPhone, setItPhone] = useState("");
  const [projectId, setProjectId] = useState();
  const [customerSiteId, setCustomerSiteId] = useState("");
  const [siteInfoData, setSiteInfoData] = useState({});

  const formatPhoneNumber = (value) => {
    if (!value) return value;

    const phoneNumber = value.replace(/[^\d]/g, ""); // Remove all non-digit characters

    if (phoneNumber.length < 4) return phoneNumber;

    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (event) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);
    setItPhone(formattedPhoneNumber);
  };

  const saveSiteInfo = () => {
    const newSiteInfo = {
        productConfigurationId: siteInfoData.productConfigurationId,
        projectId: projectId,
        csId: customerSiteId,
        itContact: itContact,
        itContactPhone: itPhone
      };

    setLoading(true);

    axios
      .put("api/SiteInfo/updateItContact", newSiteInfo)
      .then(() => {
        setSnackbarOpen(true);
        onSave();
      })
      .catch((error) => {
        console.error(error);                   
        alert(error.response?.data || "Error saving IT Contact info. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  }

  useEffect(() => {
    setSiteInfoData(siteInfo);
  }, [siteInfo]);

  useEffect(() => {
    if (siteInfo) {
        setItContact(siteInfo.itContact || "");
        setItPhone(siteInfo.itContactPhone || "");
        setCustomerSiteId(siteInfo.csId);
        setProjectId(siteInfo.projectId);
    }
  }, [siteInfo]);

  const gridItemProps = {
    xs: 6,
    container: true,
    justifyContent: "center",
  };

  return (
    <>
      <Button variant="contained" color="primary" style={{ fontFamily: "'Roboto Mono', monospace", display: 'table-header-group', marginLeft: 'auto', marginTop: '15%', marginBottom: '15%'}} onClick={handleOpen}>Edit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            height: "80vh",
            maxHeight: "300px",
            overflowY: "auto",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
            disabled={loading}
          >
            X
          </Button>

          <Typography
            variant="h6"
            component="h2"
            style={{ fontFamily: "'Roboto Mono', monospace", paddingBottom: "20px" }}
          >
            Edit Internal Info
          </Typography>

          <Grid
              container
              rowSpacing={0}
              style={{ padding: "30px" }}
            >

            <Grid item {...gridItemProps}>
                <TextField
                label="IT Contact"
                value={itContact}
                onChange={(e) => setItContact(e.target.value)}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
                sx={{
                  "& .MuiInputBase-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                }}
                />
            </Grid>

            <Grid item {...gridItemProps}>
              <TextField
                label="IT Contact Phone"
                value={itPhone}
                onChange={handlePhoneChange}
                style={{ width: "100%", marginBottom: "20px", paddingRight: "5px" }}
                sx={{
                  "& .MuiInputBase-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                  "& .MuiInputLabel-root": {
                    fontFamily: "Roboto Mono, monospace",
                  },
                }}
              />
            </Grid>
             
          </Grid>

          <div
            style={{ position: "absolute", right: "10px" }}
          >
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green", fontFamily: "'Roboto Mono', monospace" }}
              onClick={saveSiteInfo}
              disabled={loading}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          IT Contact Info updated!
        </MuiAlert>
      </Snackbar>
    </>
  );
}


