import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography, TextField, CircularProgress } from "@mui/material";


export default function QuickEditModal({ open, onClose, fieldName, currentValue, onSave, loading, fieldTitle}) {
    const [value, setValue] = useState(currentValue);

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue]);

    const handleSave = async () => {
        await onSave(value);
        setValue("");
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="quick-edit-modal"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,        
                }}
            >
                <Button
                    variant="outlined"
                    onClick={onClose}
                    style={{ position: "absolute", top: "10px", right: "10px"}}
                    disabled={loading}
                >
                    X
                </Button>

                <Typography variant="h6" component="h2" style={{ fontFamily: "'Roboto Mono', monospace", marginBottom: "20px" }}>
                    Edit {fieldTitle}
                </Typography>

                <TextField 
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    style={{ marginBottom: "20px" }}
                    sx={{
                        "& .MuiInputBase-root": {
                          fontFamily: "Roboto Mono, monospace",
                        },
                        "& .MuiInputLabel-root": {
                          fontFamily: "Roboto Mono, monospace",
                        },
                      }}
                />

                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px'}}>
                    {loading && <CircularProgress size={24} />}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={loading}
                        sx={{ fontFamily: "'Roboto Mono', monospace" }}
                    >
                        Save
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}
