import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function AddDesignSet({ onDesignSetAdded, productDocumentType, productType, formType }) {
  const [open, setOpen] = useState(false);
  const [designSetName, setDesignSetName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => {
    setOpen(false);
    setDesignSetName("");
    setSelectedFiles([]);
  };

  const handleConfirmOpen = () => {
    if (productDocumentType === 1) {
      setConfirm(true);
    }
    else {
      saveDesignSet();
    }
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  const saveDesignSet = async () => {
    handleConfirmClose();

    const formData = new FormData();

    formData.append("DesignSetName", designSetName);
    formData.append("DocumentTypeName", productDocumentType);
    formData.append("ProductType", productType);

    selectedFiles.forEach((file) => {
      formData.append("DocumentFile", file);
    });

    setLoading(true);

    try {
      await axios.post("/api/ProductDocumentation/createDesignDocs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSnackbarOpen(true);
      setDesignSetName("");
      setSelectedFiles([]);
      onDesignSetAdded();
    } catch (error) {
      alert(error.response?.data || `Error saving ${formType}. Please try again.`);
    } finally {
      setLoading(false);
      handleModalClose();
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleModalOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add {formType}
          </Typography>
          <TextField
            multiline
            label="Enter name of design set"
            value={designSetName}
            onChange={(e) => setDesignSetName(e.target.value)}
            style={{ width: "100%", paddingBottom: "30px" }}
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ paddingBottom: "30px", display: "block" }}
          />
          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}
            <Button style={{ color: "green" }} onClick={handleConfirmOpen} disabled={loading || !designSetName || selectedFiles.length === 0}>
              Save
            </Button>
          </div>

          <Dialog
            open={confirm}
            keepMounted
            onClose={handleConfirmClose}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Create new Design set?"}</DialogTitle>
            <DialogContent>
                From this point on any additional files added to this design set will be treated as revisions to the set (see information icon for more details).

                Please make sure you have all files required for initial save of this new design set in order to avoid undesired side effects.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose}>Cancel</Button>
              <Button style={{color: 'green'}} onClick={saveDesignSet}>Confirm</Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {formType} saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
