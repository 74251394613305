import React, { useState } from "react";
import { Box, Button, Modal, Typography, Snackbar, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { projectManageAxios as axios } from '../../utils/AxiosUtility';

export default function AddDocumentToSet({ onDesignSetAdded, productDocumentType, subtypeId }) {
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const handleModalOpen = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setSelectedFiles([]);
  };

  const handleConfirmOpen = () => {
    if (productDocumentType === 1) {
      setConfirm(true);
    }
    else {
      saveDesignSet();
    }
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  const saveDesignSet = async () => {
    handleConfirmClose();

    const formData = new FormData();

    formData.append("DocumentSubtypeId", subtypeId);
    formData.append("DocumentTypeName", productDocumentType);

    selectedFiles.forEach((file) => {
      formData.append("DocumentFile", file);
    });

    setLoading(true);

    try {
      await axios.post("/api/ProductDocumentation/addDocument", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSnackbarOpen(true);
      setSelectedFiles([]);
      onDesignSetAdded();
    } catch (error) {
      alert(error.response?.data || `Error saving file. Please try again.`);
    } finally {
      setLoading(false);
      handleModalClose();
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={handleModalOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleModalClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add File (One at a time)
          </Typography>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ paddingBottom: "30px", display: "block" }}
          />
          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}
            <Button style={{ color: "green" }} onClick={handleConfirmOpen} disabled={loading || selectedFiles.length === 0}>
              Save
            </Button>
          </div>

          <Dialog
            open={confirm}
            keepMounted
            onClose={handleConfirmClose}
            aria-describedby="alert-dialog-slide-description"
          >
          <DialogTitle>{"Create new Design set?"}</DialogTitle>
            <DialogContent>
                Adding a new file (or revised file) to this design set will create a new design set 
                with updated revision number containing all previous files and new revised file.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose}>Cancel</Button>
              <Button style={{color: 'green'}} onClick={saveDesignSet}>Confirm</Button>
            </DialogActions>
          </Dialog>

        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          File saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}
