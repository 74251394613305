import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Paper, useTheme } from '@mui/material';
import { systemsIntegrationAxios as siAxios } from "../../../utils/AxiosUtility";
import { projectManageAxios as pmAxios } from "../../../utils/AxiosUtility";
import EditSiteInfo from './EditSiteInfo';

export default function SiteInfoTable({ id, userCanEdit }) {
  const borderStyle = { border: null };
  const externalBorderStyle = {
    borderTop: '2px solid #BEBEBE',
    borderRight: '2px solid #BEBEBE',
    borderBottom: '2px solid #BEBEBE',
    borderLeft: '2px solid #BEBEBE'
  };
  const [siteInfoData, setSiteInfoData] = useState({});
  const [siteUnitData, setSiteUnitData] = useState();
  const [projectId, setProjectId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const onEdit = () => {
    fetchData();
  };

  const fetchData = () => {
    const endpoint = `api/ProductSummary/getProductSummaryFromPcId/${id}`;

    siAxios.get(endpoint)
        .then((response) => {
            setSiteInfoData(response.data);
            setProjectId(response.data.projectId);
            setIsLoading(false);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
            if (error.message) {
                alert(error.message);
            } else {
                alert("Error fetching Site info.");
            }
        });
  }

  const fetchSiteProducts = () => {
    const endpoint = `api/Onboarding/siteInfo/${projectId}`;

    pmAxios.get(endpoint)
      .then((response) => {
        setSiteUnitData(response.data.units);
      })
      .catch((error) => {
        console.error(error);
        if (error.message) {
          alert(error.message);
        } else {
          alert("Error fetching Site info.");
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (projectId) {
      fetchSiteProducts();
    }
  }, [projectId]);

  return (
    <TableContainer component={Paper}>
      <Table
        style={{ borderCollapse: 'collapse', ...externalBorderStyle, minHeight: '590px', maxHeight: '590px' }}
        sx={{
          '& .MuiTableCell-root': {
            padding: '4px',
            fontSize: 'clamp(0.6rem, 1vw, 0.75rem)',
            height: '36px',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            minWidth: '100px',
            backgroundColor: 'inherit'
          },
          '& .MuiTableCell-head': {
            fontWeight: 'bold',
            backgroundColor: 'inherit',
            height: '65px'
          },
          '& .MuiTableRow-root': {
            height: '33px',
          },
          '& th, & td': {
            textAlign: 'center',
          },
          '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="center" style={{ ...borderStyle, fontWeight: 'bold' }}>
              <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto">
                <Typography variant='h6' gutterBottom ml={12} style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold', fontSize: '27px', paddingRight: userCanEdit ? null : '10%', whiteSpace: 'nowrap' }}>
                  Site Information
                </Typography>
                {!isLoading && siteInfoData && (
                  <Box ml={6}>
                    {userCanEdit ?
                      <EditSiteInfo pcId={id} siteInfo={siteInfoData} onSave={fetchData} />
                      : null
                    }
                  </Box>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Customer Name:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.customerMaster}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Site Name:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.siteName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Account Manager:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.csAccountManager}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Site Units:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteUnitData}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Site Address:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.siteAddress}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Site Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.siteContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>Site Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{formatPhoneNumber(siteInfoData.siteContactPhone)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>IT Contact:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{siteInfoData.itContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>IT Contact Phone:</TableCell>
            <TableCell colSpan={2} align="center" style={borderStyle} sx={{ fontFamily: "'Roboto Mono', monospace" }}>{formatPhoneNumber(siteInfoData.itContactPhone)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

