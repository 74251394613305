import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Card, CardMedia } from "@mui/material";

export default function ImageDisplayModal({ open, onClose, form }) {
  const [isLandscape, setIsLandscape] = useState(true);

  useEffect(() => {
    if (form?.documentLink) {
      const img = new Image();
      img.src = form.documentLink;

      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight;
        setIsLandscape(aspectRatio > 1);
        form.documentWidth = img.naturalWidth;
      };
    }
  }, [form]);

  const handleClose = (e) => {
    if (e.target.getAttribute("data-close-modal")) {
      onClose();
    }
  };

  return (
    <Box
      sx={{
        display: open ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1300,
      }}
      onClick={handleClose}
      data-close-modal
    >
      <Card
        sx={{
          position: "relative",
          width: isLandscape ? "54%" : "40%",
          maxWidth: "1080px",
          height: "auto",
          maxHeight: "90%",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          gap: "5%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          {form ? (
            <>
              <Typography
                variant="h6"
                align="center"
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: 700,
                  paddingBottom: "3%",
                }}
              >
                {form.formType}
              </Typography>
              <Box
                sx={{
                  marginBottom: "3%",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={onClose}
                  sx={{
                    marginLeft: "5%",
                    verticalAlign: "middle",
                  }}
                >
                  X
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
        {form ? (
          <>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "125%",
                  height: "auto",
                  maxHeight: "125%",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  zIndex: -1,
                  borderRadius: "8px",
                }}
              />
              <CardMedia
                component="img"
                image={form.documentLink}
                alt={form.formType}
                title={form.formType}
                sx={{
                  objectFit: "contain",
                  minHeight: isLandscape ? "300px" : "400px",
                  maxHeight: isLandscape ? "500px" : "600px",
                  minWidth: isLandscape ? "500px" : "300px",
                  maxWidth: isLandscape ? "800px" : "450px",
                  borderRadius: "4px",
                }}
              />
            </Box>
          </>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No Form Selected
          </Typography>
        )}
      </Card>
    </Box>
  );
}


