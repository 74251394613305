import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import MuiAlert from "@mui/material/Alert";
import { systemsIntegrationAxios as axios } from "../../../utils/AxiosUtility";
import EditNetworkInfo from './EditNetworkInfo';
import QuickEditModal from './QuickEditModal';
import { set } from 'date-fns';

export default function NetworkInfoTable({ id, userCanEdit }) {
  const [rowId, setRowId] = useState(id);
  const [networkInfo, setNetworkInfo] = useState({});
  const [networkType, setNetworkType] = useState('');
  const [lanDhcp, setLanDhcp] = useState(false);
  const [wanDhcp, setWanDhcp] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [quickEditOpen, setQuickEditOpen] = useState(false);
  const [quickEditField, setQuickEditField] = useState('');
  const [quickEditTitle, setQuickEditTitle] = useState('');
  const [quickEditValue, setQuickEditValue] = useState('');
  const theme = useTheme();
  const containerBorderStyle = { border: '2px solid #BEBEBE' };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
  
    const formattedNumber = phoneNumber.replace(/\D+/g, ""); // Remove non-numeric characters
    const areaCode = formattedNumber.slice(0, 3);
    const firstThree = formattedNumber.slice(3, 6);
    const lastFour = formattedNumber.slice(6);
  
    return `(${areaCode}) ${firstThree}-${lastFour}`;
  };

  const fetchData = async () => {
    try {
      const networkInfoEndpoint = `api/NetworkInfo/networkInfoFromDeviceId/${rowId}`;
      const response = await axios.get(networkInfoEndpoint);
      setNetworkInfo(response.data);
      setNetworkType(response.data.networkType);
      setLanDhcp(response.data.lanDhcp !== null ? response.data.lanDhcp : false);
      setWanDhcp(response.data.wanDhcp !== null ? response.data.wanDhcp : false);
    }
    catch (error) {
      setIsLoading(false);
      console.error("Error");
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [rowId]);

  const handleQuickEdit = async (newValue) => {
    try {
      setIsLoading(true);

      const updateNetworkInfo = {
        ...networkInfo,
        [quickEditField]: newValue
      };

      await axios.put("api/NetworkInfo/updateNetworkInfo", updateNetworkInfo);

      setNetworkInfo(updateNetworkInfo);
      setQuickEditOpen(false);
      setSnackbarOpen(true);
    }
    catch (error) {
      console.error("Error");
      alert(JSON.stringify(error.response?.data) || "Error saving network info. Please try again.");
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = async (event) => {
    const { name, checked } = event.target;
    
    if (name === 'lanDhcp') setLanDhcp(checked);
    if (name === 'wanDhcp') setWanDhcp(checked);

    const newNetworkInfo = {
      ...networkInfo,
      lanDhcp: name === 'lanDhcp' ? checked : lanDhcp,
      wanDhcp: name === 'wanDhcp' ? checked : wanDhcp
    };

    try {
      setIsLoading(true);

      await axios.put("api/NetworkInfo/updateNetworkInfo", newNetworkInfo);

      setNetworkInfo((prevInfo) => ({
        ...prevInfo,
        [name]: checked,
      }));

      setSnackbarOpen(true);
    }
    catch (error) {
      console.error("Error");
      alert(JSON.stringify(error.response?.data) || "Error saving network info. Please try again.");
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleCellDoubleClick = (fieldName, value, fieldTitle) => {
    if (!userCanEdit) return;
    
    setQuickEditField(fieldName);
    setQuickEditTitle(fieldTitle);
    setQuickEditValue(value || '');
    setQuickEditOpen(true);
  };

  const editableCellProps = userCanEdit ? {
    style: { cursor: 'pointer' },
  } : {};

  return (
    <>
      <TableContainer component={Paper} style={containerBorderStyle}>
        <Table
          style={{ borderCollapse: 'collapse', minHeight: '500px', maxHeight: '500px' }} 
          sx={{
            '& .MuiTableCell-root': {
              padding: '4px', 
              fontSize: '0.875rem', 
              backgroundColor: 'inherit',
            },
            '& .MuiTableCell-head': {
              fontWeight: 'bold',
              backgroundColor: 'inherit',
            },
            '& .MuiTableRow-root': {
              height: '24px',
            },
            '& th, & td': {
              whiteSpace: 'nowrap', 
              textAlign: 'center', 
            },
            '&:hover': { backgroundColor: theme.palette.mode === 'light' ? '#F3F3F3' : '#252525' },
            '& .no-border': {
              border: 'none', 
            },
            '& .bottom-border-only': {
              borderTop: 'none',
              borderBottom: theme.palette.mode === 'light' ? '1px solid #D2D2D2' : '1px solid #4D4D4D'
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align="center" style={{ fontWeight: 'bold' }}>
                <Box display="inline-flex" alignItems="center" justifyContent="space-around" width="auto" marginLeft='15%'>
                  <Typography variant='h6' gutterBottom style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold', fontSize: '27px', paddingRight: userCanEdit ? null : '12%'}}>
                    Network Information
                  </Typography>
                  {!isLoading && networkInfo && (
                    <Box ml={2}>
                      {userCanEdit ?
                        <EditNetworkInfo lanDhcp={lanDhcp} wanDhcp={wanDhcp} netInfo={networkInfo} netType={networkType} onSave={fetchData} />
                        : null
                      }
                    </Box>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>
                <FormControlLabel
                  value="lanDhcp"
                  control={
                    <Checkbox 
                      sx={{ transform: 'scale(0.7)', marginLeft: '-30px' }} 
                      checked={lanDhcp}
                      onChange={userCanEdit ? handleCheckboxChange : null}
                      name='lanDhcp'
                    />
                  }
                  label="DHCP"
                  labelPlacement="bottom"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.5rem', marginLeft: '-30px' } }}
                />
                LAN
              </TableCell>
              <TableCell align="center" style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>
                <FormControlLabel
                  value="wanDhcp"
                  control={
                    <Checkbox 
                      sx={{ transform: 'scale(0.7)', marginLeft: '-30px' }}
                      checked={wanDhcp}
                      onChange={userCanEdit ? handleCheckboxChange : null}
                      name='wanDhcp'
                    />
                  }
                  label="DHCP"
                  labelPlacement="bottom"
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.5rem', marginLeft: '-30px' } }}
                />
                WAN
              </TableCell>
              <TableCell align="center" style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>Cellular</TableCell>
            </TableRow>
          </TableBody>
          
          <TableBody>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>IP:</TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('lanIpAddress', networkInfo.lanIpAddress, 'LAN IP Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.lanIpAddress}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('wanIpAddress', networkInfo.wanIpAddress, 'WAN IP Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.wanIpAddress}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('cellIpAddress', networkInfo.cellIpAddress, 'Cellular IP Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.cellIpAddress}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>Subnet Mask:</TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('lanSubnetMask', networkInfo.lanSubnetMask, 'LAN Subnet Mask')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.lanSubnetMask}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('wanSubnetMask', networkInfo.wanSubnetMask, 'WAN Subnet Mask')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.wanSubnetMask}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('cellSubnetMask', networkInfo.cellSubnetMask, 'Cellular Subnet Mask')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.cellSubnetMask}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>Gateway:</TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('lanGateway', networkInfo.lanGateway, 'LAN Gateway')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.lanGateway}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('wanGateway', networkInfo.wanGateway, 'WAN Gateway')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.wanGateway}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('cellGateway', networkInfo.cellGateway, 'Cellular Gateway')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.cellGateway}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>DNS 1:</TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('lanDns1', networkInfo.lanDns1, 'LAN DNS 1')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.lanDns1}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('wanDns1', networkInfo.wanDns1, 'WAN DNS 1')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.wanDns1}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('cellDns1', networkInfo.cellDns1, 'Cellular DNS 1')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.cellDns1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DNS 2:</TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('lanDns2', networkInfo.lanDns2, 'LAN DNS 2')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.lanDns2}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('wanDns2', networkInfo.wanDns2, 'WAN DNS 2')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.wanDns2}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('cellDns2', networkInfo.cellDns2, 'Cellular DNS 2')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.cellDns2}
              </TableCell>
            </TableRow>
            <TableRow className="no-border">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={1} style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>SIM Carrier</TableCell>
              <TableCell colSpan={1} style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>SIM IP Address</TableCell>
              <TableCell colSpan={2} style={{ fontFamily: "'Roboto Mono', monospace", fontWeight: 'bold' }}>SIM ICCID</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                onDoubleClick={() => handleCellDoubleClick('sim1Carrier', networkInfo.sim1Carrier, 'SIM 1 Carrier')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                SIM 1: {networkInfo.sim1Carrier}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('sim1Ip', networkInfo.sim1Ip, 'SIM 1 IP Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.sim1Ip}
              </TableCell>
              <TableCell 
                colSpan={2} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('sim1Iccid', networkInfo.sim1Iccid, 'SIM 1 ICCID')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.sim1Iccid}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                onDoubleClick={() => handleCellDoubleClick('sim2Carrier', networkInfo.sim2Carrier, 'SIM 2 Carrier')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                SIM 2: {networkInfo.sim2Carrier}
              </TableCell>
              <TableCell 
                colSpan={1} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('sim2Ip', networkInfo.sim2Ip, 'SIM 2 IP Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.sim2Ip}
              </TableCell>
              <TableCell 
                colSpan={2} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('sim2Iccid', networkInfo.sim2Iccid, 'SIM 2 ICCID')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.sim2Iccid}
              </TableCell>
            </TableRow>
            <TableRow className="no-border">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow className="bottom-border-only">
              <TableCell colSpan={4} className="no-border"></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>Router IMEI:</TableCell>
              <TableCell 
                colSpan={3} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('router', networkInfo.router, 'Router')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.router}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>DDNS:</TableCell>
              <TableCell 
                colSpan={3} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('ddns', networkInfo.ddns, 'DDNS')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.ddns}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>MAC Address:</TableCell>
              <TableCell 
                colSpan={3} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('macAddress', networkInfo.macAddress, 'MAC Address')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.macAddress}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Serial Number:</TableCell>
              <TableCell 
                colSpan={3} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('serialNumber', networkInfo.serialNumber, 'Serial Number')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.serialNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontFamily: "'Roboto Mono', monospace" }}>Default Password:</TableCell>
              <TableCell 
                colSpan={3} 
                align="center"
                onDoubleClick={() => handleCellDoubleClick('defaultPassword', networkInfo.defaultPassword, 'Default Password')}
                {...editableCellProps}
                style={{ fontFamily: "'Roboto Mono', monospace" }}
              >
                {networkInfo.defaultPassword}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Network Info updated!
        </MuiAlert>
      </Snackbar>

      <QuickEditModal 
        open={quickEditOpen}
        onClose={() => {
          setQuickEditOpen(false)
          setQuickEditField('');
          setQuickEditValue('');
        }}
        fieldName={quickEditField}
        fieldTitle={quickEditTitle}
        currentValue={quickEditValue}
        onSave={handleQuickEdit}
        loading={isLoading}
      />
    </>
  );
}


