import React, { useState, useEffect } from "react";
import { projectManageAxios as axios } from '../../utils/AxiosUtility';
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, MenuItem } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MuiAlert from '@mui/material/Alert';

export default function AddDocument({ onDocumentAdded, products, productNames, productTypes }) {
  const [open, setOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProdType, setSelectedProdType] = useState("");
  const [selectedSubTypeId, setSelectedSubTypeId] = useState(null);
  const [selectedDocumentSet, setSelectedDocumentSet] = useState([]);
  const [selectedDocumentSetName, setSelectedDocumentSetName] = useState("");
  const [selectedFileSetType, setSelectedFileSetType] = useState("");
  const [selectedVersionNo, setSelectedVersionNo] = useState("");
  const [formOptions, setFormOptions] = useState([]);
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { instance } = useMsal();
  const [name, setName] = useState(null);
  const activeAccount = instance.getActiveAccount();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name.split(' ')[0]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setSelectedTier("");
    setDescription("");
    setFileType("");
    setSelectedProdType("");
    setSelectedDocumentSetName("");
    setLoading(false);
    setOpen(false);
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(event.target.files[0]);
      setFileName(file.name);
      setSelectedProdType(file.type);
    }
  };

  const handleTierChange = (event) => {
    const selectedName = event.target.value;
    setSelectedTier(selectedName);

    const selectedProduct = tierOneOptions.find(product => product.value === selectedName);

    if (selectedProduct) {
      setFileType(selectedProduct.type);
      setSelectedProdType(selectedProduct.type);
    }
  };

  const handleFileTypeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = formOptions.find(option => option.documentSubtypeId === selectedValue) || 
      generalUnitOptions.find(option => option.value === selectedValue);

    if (formOptions.find(option => option.documentSubtypeId === selectedValue)) {
      const selectedDoc = formOptions.find(option => option.documentSubtypeId === selectedValue);
      setSelectedFileSetType(selectedDoc.documentName);
      setSelectedVersionNo(selectedDoc.versionNo);
    }

    setSelectedDocumentSetName(selectedValue);
    setSelectedSubTypeId(selectedOption?.documentSubtypeId);
  };

  const fetchDocumentSet = async (selectedSubTypeId) => {
    if (selectedSubTypeId) {
      const docSetEndpoint = `api/Onboarding/getDocsFromSubType/${selectedSubTypeId}`;
      const docSetResponse = await axios.get(docSetEndpoint);

      setSelectedDocumentSet(docSetResponse.data);
    }
  }

  useEffect(() => {
    fetchDocumentSet(selectedSubTypeId);
  }, [selectedSubTypeId]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const mappedProducts = products.map(prod => ({
    name: prod.productName,
    type: prod.productType,
  }));

  const tierOneOptions = [
    ...mappedProducts.map(product => ({
      value: product.name,
      label: product.name,
      type: product.type,
    })),
    {
      value: "General Documents",
      label: "General Documents",
      type: "general",
    },
  ];

  const fetchSubOptions = async (selectedProdType) => {
    if (selectedProdType) {
      if ((selectedProdType === "CF" || selectedProdType === "BSU")) {
        const endpoint = `api/Onboarding/getSubTypeOptions/${selectedProdType}`;
        const subtypeResponse = await axios.get(endpoint);

        const filteredOptions = subtypeResponse.data.filter(
          (option) => option.documentSubtypeId >= 194
        );

        setFormOptions(filteredOptions);
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    fetchSubOptions(selectedProdType);
  }, [selectedProdType]);

  const generalUnitOptions = [
    { value: "Preliminary Design", label: "Preliminary Design", class: "designDocs" },
    { value: "Minor Change", label: "Minor Change", class: "designDocs" },
    { value: "Major Change", label: "Major Change", class: "designDocs" },
    { value: "Contract", label: "Contract", class: "customerDocs" },
    { value: "Customer Quote", label: "Customer Quote", class: "customerDocs" },
    { value: "Vendor Bill", label: "Vendor Bill", class: "customerDocs" },
    { value: "Internet Validation", label: "Internet Validation", class: "resourceDocs" },
    { value: "Pad Validation", label: "Pad Validation", class: "resourceDocs" },
    { value: "Power Validation", label: "Power Validation", class: "resourceDocs" },
  ];

  const renderFileOptions = () => {
    const selectedProduct = tierOneOptions.find(option => option.value === selectedTier);
    if (!selectedProduct) return null;

    if (selectedProduct.type === "CF" || selectedProduct.type === "BSU") {
      return formOptions.map(option => (
        <MenuItem
          key={option.documentSubtypeId}
          value={option.documentSubtypeId}
        >
          {option.documentName} - {option.productType} - (Set {option.versionNo})
        </MenuItem>
      ));
    }

    if (selectedProduct.type === "general") {
      return generalUnitOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ));
    }

    return null;
  };

  const shouldDisplayDropdown = () => {
    const selectedProduct = tierOneOptions.find(option => option.value === selectedTier);
    return selectedProduct ? (selectedProduct.type === "CF" || selectedProduct.type === "BSU") || selectedProduct?.type === "general" : false;
  };

  const shouldDisplayFileInput = () => {
    const selectedProduct = tierOneOptions.find(option => option.value === selectedTier);
    return selectedProduct ? (selectedProduct.type !== "CF" && selectedProduct.type !== "BSU") : false;
  };

  const shouldDisplayDescription = () => {
    const selectedProduct = tierOneOptions.find(option => option.value === selectedTier);
    return selectedProduct ? (selectedProduct.type !== "CF" && selectedProduct.type !== "BSU") : false;
  };

  const handleDocumentSetSave = (documentSet) => {
    const userName = name;
    const isoFormattedDate = new Date().toISOString();
  
    const uploadDocuments = (documents) => {
      const formData = new FormData();
  
      documents.forEach((document, index) => {
        formData.append(`documents[${index}].SiteDocumentId`, 0);
        formData.append(`documents[${index}].CsId`, "");
        formData.append(`documents[${index}].ProjectId`, searchParams.get("id"));
        formData.append(`documents[${index}].DocumentLink`, document.documentPath);
        formData.append(`documents[${index}].UserName`, userName);
        formData.append(`documents[${index}].FileType`, `${selectedFileSetType} (Set ${selectedVersionNo})`);
        formData.append(`documents[${index}].ProductFileName`, document.documentName);
        formData.append(`documents[${index}].VersionNo`, "");
        formData.append(`documents[${index}].DateTime`, isoFormattedDate);
        formData.append(`documents[${index}].Dscrption`, document.description || "");
        formData.append(`documents[${index}].FinalDesignDoc`, false);
      });
  
      setLoading(true);
  
      return axios.post("/api/Onboarding/uploadDocumentSet", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        setSnackbarOpen(true);
        onDocumentAdded();
      })
      .catch((error) => {
        console.error("Error.");
        alert(error.response?.data?.message || "Error. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
    };
  
    uploadDocuments(documentSet);
  };
  
  const handleSave = () => {
    const userName = name;
    const isoFormattedDate = new Date().toISOString();

    const formData = new FormData();
    formData.append("Dscrption", description);
    formData.append("CsId", "");
    formData.append("ProjectId", searchParams.get("id"));
    formData.append("DocumentLink", "");
    formData.append("UserName", userName);
    formData.append("FileType", fileType);
    formData.append("ProductFileName", fileName);
    formData.append("VersionNo", "");
    formData.append("DateTime", isoFormattedDate);

    if (selectedFile) {
      formData.append("DocumentFile", selectedFile);
    }

    setLoading(true);

    axios.post("/api/Onboarding/uploadDocument", formData, {})
      .then(() => {
        setSnackbarOpen(true);
        onDocumentAdded();
      })
      .catch((error) => {
        console.error("Error.");
        alert(error.response?.data || "Error. Please try again.");
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  return (
    <>
      <Button onClick={handleOpen}>Add</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Button variant="outlined" onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '10px' }} disabled={loading}>X</Button>

          <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
            Add Document
          </Typography>

          <TextField
            select
            label="Select Tier"
            value={selectedTier}
            onChange={handleTierChange}
            style={{ width: "100%", paddingBottom: "20px" }}
          >
            {tierOneOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {shouldDisplayDropdown() && (
            <>
              <TextField
                select
                label="File Type"
                value={selectedDocumentSetName}
                onChange={handleFileTypeChange}
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                {renderFileOptions()}
              </TextField>
            </>
          )}

          {shouldDisplayFileInput() && (
            <>
              <input type="file" onChange={handleFileChange} style={{ width: "100%", paddingBottom: "20px" }} />
            </>
          )}

          {shouldDisplayDescription() && (
            <TextField
              multiline
              label="Enter Description"
              value={description}
              onChange={handleDescriptionChange}
              style={{ width: "100%", paddingBottom: "20px" }}
            />
          )}

          <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
            {loading && <CircularProgress />}

            <Button
              style={{ color: "green" }}
              onClick={() => {
                if (selectedProdType === "CF" || selectedProdType === "BSU") {
                  handleDocumentSetSave(selectedDocumentSet);
                } else {
                  handleSave();
                }
              }}
              disabled={loading || (!fileType && selectedProdType !== "CF" && selectedProdType !== "BSU")}
            >
              Save
            </Button>

          </div>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Document saved!
        </MuiAlert>
      </Snackbar>
    </>
  );
}


