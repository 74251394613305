import React, { useState, useEffect } from "react";
import { Box, Button, Modal, TextField, Typography, Snackbar, CircularProgress, MenuItem } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useMsal } from "@azure/msal-react";

export default function UploadFormModal({ onDocumentAdded, requiredItems }) {
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [username, setUsername] = useState(null);

    useEffect(() => {
        if (activeAccount) {
            setUsername(activeAccount.name.split(' ')[0]);
        } else {
            setUsername(null);
        }
    }, [activeAccount]);

    const handleSnackbarClose = () => setSnackbarOpen(false);
  
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileName(file ? file.name : "");
    };

    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const fileOptions = requiredItems.map((item) => ({
      value: item,
      label: item,
    }))
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSave = () => {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = () => {
          onDocumentAdded(fileType, fileName, selectedFile, username, description);
          setOpen(false);
          setFileType("");
          setFileName("");
          setSelectedFile(null);
          setDescription("");
          setLoading(false);
      };
      if (selectedFile) {
          reader.readAsDataURL(selectedFile);
      }
      else {
        setSnackbarOpen(true);
        setLoading(false);
      }
    };
  
    return (
      <>
        <Button
            variant="outlined"
            color="error"
            sx={{
                borderRadius: '18px',
                borderColor: 'error.main',
                color: 'error.main',
                '&:hover': {
                    backgroundColor: 'error.white',
                    borderColor: 'error.dark',
                },
            }}
            onClick={handleOpen}
        >
            Upload Form
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="upload-document-modal-title"
          aria-describedby="upload-document-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >

            <Button 
                variant="outlined" 
                onClick={handleClose} 
                style={{ position: 'absolute', top: '10px', right: '10px' }} 
                disabled={loading}
            >
                X
            </Button>

            <Typography variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
              Upload Required Form
            </Typography>
  
            <TextField
              select
              label="Form Type"
              value={fileType}
              onChange={handleFileTypeChange}
              style={{ width: "100%", paddingBottom: "20px" }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: "400px",
                      overflowY: "auto",
                    },
                  },
                },
              }}
            >
                {fileOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
  
            <input type="file" onChange={handleFileChange} style={{ width: "100%", paddingBottom: "20px" }} />

            <TextField
              multiline
              label="Enter Description"
              value={description}
              onChange={handleDescriptionChange}
              style={{ width: "100%", paddingBottom: "20px" }}
            />
  
            <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
              {loading && <CircularProgress />}
  
              <Button style={{ color: "green" }} onClick={handleSave} disabled={loading || !fileType}>
                Save
              </Button>
            </div>
          </Box>
        </Modal>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Document saved!
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
  

